import Qs from "qs";
import { Http } from "en-js";
import { Message } from "element-ui";
import { reLogin } from "./tools";
import { getRefreshToken } from "./token/setToken";

const defaultConfig = {
  channelType: "PC",
  channelcode: "PC"
};
const toLogin = () => {
  sessionStorage.removeItem("jwtToken");
  sessionStorage.removeItem("refreshToken");
  localStorage.removeItem("appSessionId");
  localStorage.removeItem("tenantId");
  sessionStorage.clear();
  window.location.href = "/user/login";
};
const axios = Http.create({
  baseURL: process.env.VUE_APP_EN_URL || "",
  timeout: 30000000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    Accept: "application/json;charset=UTF-8",
    Authorization: sessionStorage.getItem("jwtToken") || ""
  },
  transformRequest: [(data) => {
    window.requestData = JSON.parse(JSON.stringify(data));
    if (data.showData) {
      return JSON.stringify(data);
    }
    if (Object.keys(data).every((item) => item !== "importFile")) {
      return Qs.stringify(data, {
        arrayFormat: "indices",
        allowDots: true
      });
    }
    // 业务建模导入数据需要传文件
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  }]
});
const reqError = (error) => {
  console.log(error, "error");
  Promise.reject(error);
};
const resError = async(error) => {
  console.log("===>>", error.response, typeof error);
  if (error?.response?.status === 401) {
    if (sessionStorage.getItem("refreshToken")) {
      await getRefreshToken();
      error.config.headers.Authorization = sessionStorage.getItem(
        "jwtToken"
      );
      console.log(window.requestData);
      error.config.data = window.requestData;
      try {
        const res = await Http.request(error.config);
        return res.data.rspData;
      } catch (er) {
        console.log(er);
      }
    } else {
      toLogin();
    }
  }
  if (error.response && error?.response?.status !== 401) {
    const status = error.response.status;
    const statusText = error.response.statusText || "未知错误";
    const method = error.config?.method;
    const url = error.config?.url;
    Message({
      showClose: true,
      message: `${method} ${url}: ${status} ${statusText}`,
      type: "error"
    });
  }
  const res = false;
  return Promise.reject(res); // 返回接口返回的错误信息
};

axios.interceptors.request.use((config) => {
  config.headers = {
    "Content-Type": config.headers["Content-Type"] || "application/x-www-form-urlencoded;charset=UTF-8",
    Accept: "application/json;charset=UTF-8",
    Authorization: sessionStorage.getItem("jwtToken") || ""
  };
  const { data = {}, params = {} } = config;
  if (!data.channelcode) {
    Object.assign(data, defaultConfig);
  }
  data.appSessionId = data.appSessionId || localStorage.getItem("appSessionId") || "";
  // if (!data.tenantId) {
    data.tenantId = data.tenantId || localStorage.getItem("tenantId") || "";
  // }
  if (data.requestType === "stream" || params.requestType === "stream") {
    config.requestType = "blob";
    config.responseType = "arraybuffer";
  }
  config.data = data;
  const reg = /.(app|ajax)$/;
  // config.url = reg.test(config.url) ? config.url.replace(reg, () => ".app") : `${config.url}.app`;
  return config;
}, reqError);
// axios.interceptors.request.use((config) => {
//   let req = "";
//   // eslint-disable-next-line
//   for (const i in config.data) {
//     // eslint-disable-next-line
//     req += `${encodeURIComponent(i)}=${encodeURIComponent(typeof config.data[i] === Array ? JSON.stringify(config.data[i]) : config.data[i])}&`;
//   }
//   config.data = req;
//   return config;
// }, reqError);

axios.interceptors.response.use((config) => {
  console.log(config, "configconfigconfigconfig");

  if (config.status === 200 && config.config.responseType === "arraybuffer") {
    // 处理导出 excel文件
    return config;
  }
  if (config.status === 200 && config.data.code === "0") {
    // 处理 影响服务器 替换文件code
    return config.data;
  }
  /* eslint-disable */
  if (config.data.msgType === "N") {
    return config.data.rspData;
  } if (config.data.msgType === "E" && config.data.rspMsg) {
    /* eslint-disable */
    // return
    if (config.data.resCode === "AU1001") {
      return Promise.reject(new Error("Authorization参数不能为空"));
    } if (config.data.rspCode === "AU1000") {
      reLogin("由于您长时间未操作，系统已自动注销，3s后跳转到登录页面");
      const res = false;
      // toLogin();
      return Promise.reject(res);
    } if (config.data.rspCode === "RB001") {
      // 用户账号或密码错误
      return Promise.reject(new Error("用户账号或密码错误"));
    } if (config.data.rspCode === "AP5000") { // session失效
      reLogin("由于您长时间未操作，系统已自动注销，3s后跳转到登录页面");
      const res = false;
      // toLogin();
      return Promise.reject(res);
    } if (config.data.rspCode === "AP5001") {
      reLogin("您已经修改密码，请重新登录，3s后跳转到登录页面");
      const res = false;
      // toLogin();
      return Promise.reject(res);
    } if (config.data.rspCode === "AP5002") {
      reLogin("您的帐号已在另外一台设备登录，请重新登录，3s后跳转到登录页面");
      const res = false;
      // toLogin();
      return Promise.reject(res);
    } if (config.data.rspCode === "AP5003") {
      reLogin("您的帐号已超过授权时间，请重新登录，3s后跳转到登录页面");
      const res = false;
      // toLogin();
      return Promise.reject(res);
    }else{
      Message.error(config.data.rspMsg);
    }
   
    if (config.config.url.indexOf("mdQueryApproveList") > -1) {
      const error = {
        flag: "customError",
        error: config.data.rspMsg
      };
      return Promise.reject(error);
    }
    // 登录失败会需要验证码
    if (config.config.url.indexOf("loginAction") > -1) {
      return Promise.reject({
        message: config.data.rspMsg,
        appSessionId: config.data.rspData?.appSessionId
      });
    }
    return Promise.reject(config.data.rspMsg);
    // return Promise.reject(new Error(config.data.rspMsg));
  }
  return Promise.reject(new Error("操作失败，请稍后重试。"));
}, resError);
// axios.interceptors.response.use(null, (error) => {
//   if (error?.response?.status === 401) {
//     toLogin();
//     return false;
//   }
//   if (error === false || error.flag === "customError") {
//     return Promise.reject(error);
//   }
//   let res = error?.message || error?.toString() || "未知错误";
//   // 兼容后台传回老系统跳转代码
//   if(res.indexOf("3秒后跳转到修改页") != -1){
//     MessageBox.alert('密码已重置，3秒后跳转到修改页', "提示", {
//       confirmButtonText: "我知道了",
//       modal: false,
//       customClass: "requset-error-dialog",
//       type: "error"
//     })
//     setTimeout(function () {
//       localStorage.removeItem("appSessionId");
//       localStorage.removeItem("tenantId");
//       localStorage.setItem("showForget", '1');
//       window.location.href='/user/login'
//       return;
//   }, 3000);
//   return
//   }
//   PopupManager.nextZIndex();
//   PopupManager.nextZIndex();
//   // MessageBox.alert(res, "提示", {
//   //   confirmButtonText: "我知道了",
//   //   modal: false,
//   //   customClass: "requset-error-dialog",
//   //   type: "error"
//   // }).catch();
//   Message({
//     showClose: true,
//     message: `${res}`,
//     type: "error"
//   });
//   if (error.response) {
//     res = error.response.status;
//   }
//   // 线上弹窗层级有问题，先这样修复，后期排查popupManage的问题
//   setTimeout(() => {
//     const errorDialog = document.querySelector(".requset-error-dialog");
//     if (errorDialog) {
//       errorDialog.parentElement.style.zIndex = 10000;
//     }
//   });
//   return Promise.reject(error);
// });

export default axios;
