/**
 * @Author 李煜
 * @Date 2020/6/5
 * @Description vuex状态管理
 * 当前文件保存整体vuex状态
 * modules下面按模块保存
 */
import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import persistedStateList from "./persistedstate";
import main from "./modules/main";
import chat from "./modules/chat";
import businessTravel from "./modules/businessTravel";
import intelligentAccounting from "./modules/intelligentAccounting";
import mailBox from "./modules/mailBox";
import businessModel from "./modules/businessModel";
import payRollModel from "./modules/payRoll";
import reportModel from "./modules/reportModel";

Vue.use(Vuex);
// const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    isChangeOrg: 0,
    loginAccount: null,
    userInfo: {},
    componyMeta: {},
    urlParam: null,
    // https://static.enfry.cn/Storage-api/upload.api  主板烧了 暂时用生产的
    attachmentUploadUrl: "https://storage.softheluo.com/Storage-api/upload.api"
    // https://static.enfry.cn/Storage-api/upload.api?token=2FF852BF3FD8C0F6E7A7C6127FD7F526&appKey=enfrytest&ts=1597031994330
  },
  getters: {
    loginAccount: (state) => state.loginAccount,
    userInfo: (state) => state.userInfo,
    componyMeta: (state) => state.componyMeta,
    appSessionId: (state) => state.userInfo?.appSessionId || "",
    tenantId: (state) => state.userInfo?.tenantId || "",
    urlParam: (state) => state.urlParam,
    attachmentUploadUrl: (state) => state.attachmentUploadUrl
  },
  actions: {
    saveLoginAccount: ({ commit }, loginAccount) => {
      commit({
        type: "saveLoginAccount",
        loginAccount
      });
    },
    login: ({ commit, dispatch }, userInfo) => {
      commit({ type: "login", userInfo });
      dispatch("chat/connect", userInfo);
    },
    logout: ({ commit, dispatch }) => {
      commit({ type: "logout" });
      dispatch("chat/disconnect");
    },
    saveUrlParam: ({ commit }, urlParam) => {
      commit({
        type: "saveUrlParam",
        urlParam
      });
    },
    updateUserInfo: ({ commit, dispatch }, urlParam) => {
      commit({
        type: "updateUserInfo",
        urlParam
      });
      dispatch("chat/connect", urlParam);
    }
  },
  mutations: {
    saveLoginAccount(state, payload) {
      state.loginAccount = payload.loginAccount;
    },
    changeOrg(state) {
      state.isChangeOrg++;
    },
    saveComponyMeta(state, payload) {
      state.componyMeta = payload;
    },
    login(state, payload) {
      localStorage.setItem("appSessionId", payload.userInfo.appSessionId || "");
      localStorage.setItem("tenantId", payload.userInfo.tenantId || "");
      state.userInfo = payload.userInfo.userInfo;
      state.main.menuList = [];
    },
    setUserInfoByKey(state, payload) {
      state.userInfo[payload.key] = payload.value;
    },
    deleteAppSessionId(state) {
      localStorage.removeItem("appSessionId");
      state.userInfo.appSessionId = "";
    },
    updateUserInfo(state, data) {
      state.userInfo = data.urlParam;
    },
    logout(state) {
      localStorage.removeItem("appSessionId");
      localStorage.removeItem("tenantId");
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("refreshToken");
      state.userInfo = {};
    },
    saveUrlParam(state, payload) {
      state.urlParam = payload.urlParam;
    }
  },
  modules: {
    main,
    chat,
    businessTravel,
    intelligentAccounting,
    payRollModel,
    businessModel,
    reportModel,
    mailBox
  },
  plugins: [
    createPersistedState({
      key: "EN-MANAGE",
      storage: window.localStorage,
      reducer(val) {
        const r = {};
        persistedStateList.reduce((o, path) => {
          const v = _.get(val, path);
          if (!_.isUndefined(v)) {
            _.set(r, path, v);
          }
          return r;
        }, r);
        return r;
      }
    })
  ]
  // strict: debug
});
