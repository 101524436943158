import axios from "axios";

export const getRefreshToken = async() => {
  const resp = await axios.post(`${process.env.VUE_APP_EN_URL}/auth/refreshToken?refreshToken=${sessionStorage.getItem("refreshToken")}&userName=${sessionStorage.getItem("userPhone")}&pageType=2`);
  if (resp.data.msgType === "N") {
    sessionStorage.setItem("jwtToken", resp.data.rspData.jwtToken);
    sessionStorage.setItem("refreshToken", resp.data.rspData.refreshToken);
  } else {
      localStorage.removeItem("appSessionId");
      localStorage.removeItem("tenantId");
      sessionStorage.clear();
      window.location.href = "/user/login";
  }
};
